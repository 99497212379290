/** @jsxImportSource theme-ui */
import { jsx } from "theme-ui";
import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import { GenericPage } from "../data/ContentfulGenericPage";
import { SEO } from "../components/SEO";
import { DefaultLayout as Layout } from "../Layout/Default";
import CardWithImage from "../components/collectionCardWithImage/CardWithImage";
import CTA from "../components/CTA";
import BlogArticleCollection from "../components/blog/BlogArticleCollection";

const NotFoundPage = (props: any) => {
  const notFoundData = GenericPage.find((page: any) => page.url == "/404/");
  console.log(notFoundData);
  return (
    <Layout
      showMasterDisclaimer={notFoundData?.showMasterDisclaimer}
      showCustomDisclaimers={notFoundData?.showCustomDisclaimers}
      disclaimers={notFoundData?.disclaimers}
    >
      <SEO title={""} noindex={notFoundData?.seo?.no_index || undefined} />
      <div
        sx={{
          mx: "auto",
          maxWidth: "1020px",
          px: 3,
          py: ["60px", null, "75px", null],
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& h1": {
            fontSize: "48px",
            color: "brand.navy",
            lineHeight: "52px",
            mb: 2,
          },
          "& p": {
            color: "brand.navy",
          },
        }}
      >
        <CardWithImage
          cardWithImage={notFoundData?.content.find(
            (content: any) => content.name == "404 Card With Image"
          )}
        />
      </div>
    </Layout>
  );
};

export default NotFoundPage;
