/** @jsxImportSource theme-ui */
import { jsx, Box, Container } from "theme-ui";
import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";

import { Image, Link } from "../utils";

const CardWithImage = (props: any) => {
  const content = (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "375px",
        fontSize: 2,
        "& ol": {
          listStyleType: "decimal",
          pl: 4,
        },
        "& li": {
          fontWeight: "bold",
          mt: 4,
        },
        "& ol li span": {
          fontWeight: "normal",
        },
      }}
    >
      <div
        sx={{
          "& h2": {
            variant: "text.h2",
          },
        }}
      >
        {props.cardWithImage.cardWithImageHeading.heading ? (
          props.cardWithImage.cardWithImageHeading.heading
        ) : (
          <MDXRenderer>
            {props.cardWithImage.cardWithImageHeading.childMdx.body}
          </MDXRenderer>
        )}
      </div>
      <MDXRenderer>{props.cardWithImage.copy.childMdx.body}</MDXRenderer>
      {props.cardWithImage.button && (
        <Link
          link={props.cardWithImage.buttonProps}
          sx={{ mt: ["30px", null, null, "45px"] }}
        />
      )}
    </div>
  );

  return (
    <div
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: [
          props.cardWithImage.imageOrientation ? "column" : "column-reverse",
          null,
          "row",
          null,
        ],
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {props.cardWithImage.imageOrientation ? (
        <Image
          image={props.cardWithImage.image}
          sx={{ mb: ["10px", null, 0, null], pr: "10px" }}
        />
      ) : (
        content
      )}
      {props.cardWithImage.imageOrientation ? (
        content
      ) : (
        <Image
          image={props.cardWithImage.image}
          sx={{ mb: ["10px", null, 0, null], pl: "10px" }}
        />
      )}
    </div>
  );
};

export default CardWithImage;
